import { provideHttpClient, withInterceptorsFromDi, withFetch } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { CookieConfig, ProvideCookiesNgrx } from '@teamfoster/sdk/cookie-ngrx';
import { ProvideDictionaryNgrx } from '@teamfoster/sdk/dictionary-ngrx';
import { ProvideIcons } from '@teamfoster/sdk/icon';
import { ImageSharpConfig, ProvideOptimisedImageProcessor } from '@teamfoster/sdk/image';
import { ProvideMenuNgrx } from '@teamfoster/sdk/menu-ngrx';
import { environment } from 'src/environments/environment';
import { routes } from './app.routes';
import { reducers, metaReducers, appEffects, CustomSerializer } from './store';

import { provideAnimations } from '@angular/platform-browser/animations';
import { ProvideLocationNgrx } from './location/location.providers';
import { ProvideSocialsNgrx } from './social-media/socials.providers';
import { AppRouteReuseStrategy } from './app.route-reuse-strategy';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { ContentSliderComponent } from './slider/content-slider/content-slider.component';
import { ProvideSliderNgrx } from './slider/slider.providers';

const cookieConfig: CookieConfig = {
  analyticsCode: 'UA-0000000-0',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Accepteer alle cookies',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: true,
  },
  showSettings: true,
};

// This automatically creates SelectorHookParsers for each listed component:
const componentParsers: Array<HookParserEntry> = [{ component: ContentSliderComponent }];

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'disabled' })
    ),
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    provideStore(reducers, {
      metaReducers,

      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),

    provideEffects(appEffects),
    provideRouterStore({ serializer: CustomSerializer }),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    ProvideDictionaryNgrx({}),
    ProvideMenuNgrx({}),
    ProvideLocationNgrx({}),
    ProvideSocialsNgrx({}),
    ProvideSliderNgrx({}),
    ProvideIcons({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
    ProvideCookiesNgrx(cookieConfig),
    ProvideOptimisedImageProcessor(ImageSharpConfig, {
      placeholderUrl: 'https://coda.fstr.io/media/placeholder.png',
    }),
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    { provide: LOCALE_ID, useValue: 'nl' },

    importProvidersFrom(
      DynamicHooksModule.forRoot({
        globalParsers: componentParsers,
      })
    ),
  ],
};
